import React, { useEffect, useReducer, useRef, useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import './kontakt.scss';
import Container from '../components/container';
import axios from 'axios';
import Helmet from 'react-helmet';
import HeaderHr from '../components/headerHr';
import GridRow from '../components/gridRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { graphql } from 'gatsby';

function mailRequestReducer(state, action) {
  switch (action.type) {
    case 'send':
      return {isLoading: true, status: null}
    case 'success':
      return {isLoading: false, status: 'success'}
    case 'error':
      return {isLoading: false, status: 'error'}
  }
}

const Contact = ({data}) => {
  const gmapsIframeUrl = data.strapiAllgemein.Links.gmaps_iframe;
  const seo = data.strapiAllgemein.seo_kontakt;
  const recaptcha = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [hasSuccessfullCaptcha, setHasSuccessfullCaptcha] = useState(false);
  const [mailRequest, dispatchMailRequest] = useReducer(mailRequestReducer, {
    isLoading: false,
    status: null
  });

  useEffect(() => {
    setHasMounted(true);
  }, [])

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    dispatchMailRequest({type: 'send'});

    try {
      const recaptchaResponse = window.grecaptcha.getResponse();
      const contactForm = document.getElementById('contactForm');
      const formData = new FormData(contactForm);
      formData.append('g-recaptcha', recaptchaResponse);

      const response = await axios({
        method: 'POST',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/contact/send`,
        data: formData
      });

      dispatchMailRequest({type: 'success'});
      contactForm.reset();
    } catch (e) {
      dispatchMailRequest({type: 'error'});
    }
  }

  if (hasMounted) {
    window.onCaptchaLoadCallback = () => {
      window.grecaptcha.render(recaptcha.current, {
            'sitekey' : '6LcqdMEZAAAAAERynR-DFro_lIqIQiwADLPxqoQs',
            'callback' : (response) => {
              if (response) setHasSuccessfullCaptcha(true);
            }
          });
    }
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?onload=onCaptchaLoadCallback&render=explicit" async defer />
      </Helmet>
      <iframe
        className="contact__google-map"
        src={gmapsIframeUrl}
        tabIndex={0}
      />
      <Container>
        <h1>Kontakt</h1>
        <HeaderHr/>
        <div className="contact__content">
          <GridRow rowItems={2}>
            <div className="contact-details">
              <h3 className="contact-details__heading">Kontaktdetails</h3>
              <span className="contact-details__text">ÄLPIDA Intensivpflege GmbH</span>
              <span className="contact-details__text">Frau Patrizia Janetta</span>
              <span className="contact-details__text">Siegener Str. 40</span>
              <span className="contact-details__text">51580 Reichshof – Wildbergerhütte</span>
              <span className="contact-details__text">Tel.: <a href="tel:+4922979090606">02297 - 909 06 06</a></span>
            </div>
            <form className="form" id="contactForm" onSubmit={handleFormSubmit}>
              <div className="form__form-group">
                {/*<label htmlFor="firstname">Vorname</label>*/}
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  required={true}
                  placeholder="Vorname"
                />
              </div>
              <div className="form__form-group">
                {/*<label htmlFor="lastname">Nachname</label>*/}
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  required={true}
                  placeholder="Nachname"
                />
              </div>
              <div className="form__form-group">
                {/*<label htmlFor="email">E-Mail Adresse</label>*/}
                <input
                  type="email"
                  id="email"
                  name="email"
                  required={true}
                  placeholder="E-Mail Adresse"
                />
              </div>
              <div className="form__form-group">
                {/*<label htmlFor="message">E-Mail Adresse</label>*/}
                <textarea
                  id="message"
                  name="message"
                  required={true}
                  placeholder="Ihre Nachricht an uns"
                  style={{minHeight: '175px'}}
                />
              </div>
              <div className="form__recaptcha" ref={recaptcha} />
              <div className="form__form-group">
                <button className="button button_size_large-1" type="submit" disabled={!hasSuccessfullCaptcha}>
                  {mailRequest.isLoading ? (
                    <FontAwesomeIcon className="" icon={faSpinner} spin/>
                  ) : (
                    "Senden"
                  )}
                </button>
              </div>
              <span className={`form__status-text ${mailRequest.status === 'error' && 'form__status-text_error'} ${mailRequest.status === 'success' && 'form__status-text_success'}`}>
                {mailRequest.status === 'error' && 'Senden fehlgeschlagen'}
                {mailRequest.status === 'success' && 'Erfolgreich gesendet'}
              </span>
            </form>
          </GridRow>
        </div>
      </Container>

    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    strapiAllgemein {
      Links {
        gmaps_iframe
      }
      seo_kontakt {
        description
        keywords
        title
      }
    }
  }
`
